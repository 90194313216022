<template>
  <b-container fuild style="max-width: 2560px;">
    <b-card class="card-user pl-3 pr-3" no-body>
      <div class="author" >
        <div class="block block-one" ></div>
        <div class="block block-three" ></div>
        <div class="block block-four" ></div>
      </div>

      <b-card-body style="margin-top: -30px;max-height: 75px;min-height: 75px;">
        <div style="display: flex;max-width: 2560px;max-height: 60px;" >
          <h4 class="mt-4"><i class="mr-2"><svgicon class="icon" icon="icon-download-details" /></i>{{t('downloadDetails')}}</h4>
          <n-button class="mt-3" style="margin-left: auto; min-width: 120px; width: 15%; z-index: 1;"
            type="primary"
            size="md" round block
            :disabled='disableSubmit'
            :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/endpoint-secure/settings"}]'
            @click.native="saveDetails">
            {{t('save')}}
          </n-button>
        </div>
      </b-card-body>

      <template v-slot:footer>
        <div class="hr-grey mb-2" style="margin-left: -30px; margin-right: -30px;"/>
        <p>{{t('headerText')}}</p>
      </template>
    </b-card>
    <b-card>
      <form @submit.prevent="saveDetails">
        <b-row>
          <b-col xl="6" lg="12" >
            <b-card class="form-group" style="margin-bottom: 0;">
              <h4 class="card-title" style="display: flex; align-items: center;">
                <svgicon class="icon mr-2" icon="item-android" width="24" height="24" />
                <span style="margin-top: 2px;">Android</span>
              </h4>
              <b-card-text>
                <fg-input v-model="androidAppId"
                  id="android_app_id"
                  :label="t('appIdLabel')"
                  name="android_app_id"
                  placeholder="e.g. com.csp.endpointsecure"
                  autocapitalize="yes"
                  v-validate="'max:256'"
                  :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/endpoint-secure/settings"}]'
                  :error="getError('android_app_id')">
                </fg-input>
              </b-card-text>
            </b-card>
          </b-col>
          <b-col xl="6" lg="12" >
            <b-card class="form-group" style="margin-bottom: 0;">
              <h4 class="card-title" style="display: flex; align-items: center;">
                <svgicon class="icon mr-2" icon="item-ios" width="34" height="24" />
                <span style="margin-top: 4px;">iOS</span>
              </h4>
              <b-card-text>
                <fg-input v-model="iosAppName"
                  id="ios_app_name"
                  :label="t('appNameLabel')"
                  name="ios_app_name"
                  placeholder="e.g. cspendpointsecure"
                  autocapitalize="yes"
                  v-validate="'max:256'"
                  :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/endpoint-secure/settings"}]'
                  :error="getError('ios_app_name')">
                </fg-input>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col xl="6" lg="12">
            <b-card class="form-group" style="margin-bottom: 0;">
              <h4 class="card-title" style="display: flex; align-items: center;">
                <svgicon class="icon mr-2" icon="item-windows" width="24" height="24" />
                <span style="margin-top: 4px;">Windows x64</span>
              </h4>
              <b-card-text >
                <fg-input v-model="windowsX64InstallationPath"
                  id="windowsx64_installation_path"
                  :label="t('installationPathLabel')"
                  name="windowsx64_installation_path"
                  placeholder="e.g. https://csp_server/endpointsecure_windows_x64/ep_win_64.exe"
                  autocapitalize="yes"
                  v-validate="'url|max:1024'"
                  :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/endpoint-secure/settings"}]'
                  :error="getError('windowsx64_installation_path')">
                </fg-input>
              </b-card-text>
              <b-card-text >
                <fg-input v-model="windowsX64UpdatePath"
                  id="windowsx64_update_path"
                  :label="t('updatePathLabel')"
                  name="windowsx64_update_path"
                  placeholder="e.g. https://csp_server/endpointsecure_windows_x64/ep_win_64_update.exe"
                  autocapitalize="yes"
                  v-validate="'url|max:1024'"
                  :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/endpoint-secure/settings"}]'
                  :error="getError('windowsx64_update_path')">
                </fg-input>
              </b-card-text>
              <b-card-text style="padding-left: 20px;">
                <label for='windowsx64_version'>{{t('versionNumberLabel')}}</label>
                <b-row style="margin-left: 0; margin-right: 0;">
                  <b-col style="padding-left: 0; padding-right: 0;" xl="3" lg="12">
                    <fg-input v-model="windowsX64Version"
                      id="windowsx64_version"
                      name="windowsx64_version"
                      placeholder="e.g. 10.1.123456"
                      autocapitalize="yes"
                      v-validate="'max:256'"
                      :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/endpoint-secure/settings"}]'
                      :error="getError('windowsx64_version')">
                    </fg-input>
                  </b-col>
                  <b-col xl="9" lg="12" style="padding-right: 0;">
                    <p class="mt-2">{{t('versionNumberWarning')}}</p>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card>
          </b-col>
          <b-col xl="6" lg="12">
            <b-card class="form-group" style="margin-bottom: 0;">
              <h4 class="card-title" style="display: flex; align-items: center;">
                <svgicon class="icon mr-2" icon="item-apple" width="24" height="24" />
                <span style="margin-top: 4px;">macOS</span>
              </h4>
              <b-card-text>
                <fg-input v-model="macOsInstallationPath"
                  id="macos_installation_path"
                  :label="t('installationPathLabel')"
                  name="macos_installation_path"
                  placeholder="e.g. https://csp_server/endpointsecure_macOS/ep_macos.xxx"
                  autocapitalize="yes"
                  v-validate="'url|max:1024'"
                  :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/endpoint-secure/settings"}]'
                  :error="getError('macos_installation_path')">
                </fg-input>
              </b-card-text>
              <b-card-text >
                <fg-input v-model="macOsUpdatePath"
                  id="macos_update_path"
                  :label="t('updatePathLabel')"
                  name="macos_update_path"
                  placeholder="e.g. https://csp_server/endpointsecure_macOS/ep_macos_update.xxx"
                  autocapitalize="yes"
                  v-validate="'url|max:1024'"
                  :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/endpoint-secure/settings"}]'
                  :error="getError('macos_update_path')">
                </fg-input>
              </b-card-text>
              <b-card-text style="padding-left: 20px;">
                <label for='macos_version'>{{t('versionNumberLabel')}}</label>
                <b-row style="margin-left: 0; margin-right: 0;">
                  <b-col style="padding-left: 0; padding-right: 0;" xl="3" lg="12">
                    <fg-input v-model="macOsVersion"
                      id="macos_version"
                      name="macos_version"
                      placeholder="e.g. 10.1.123456"
                      autocapitalize="yes"
                      v-validate="'max:256'"
                      :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/endpoint-secure/settings"}]'
                      :error="getError('macos_version')">
                    </fg-input>
                  </b-col>
                  <b-col xl="9" lg="12" style="padding-right: 0;">
                    <p class="mt-2">{{t('versionNumberWarning')}}</p>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col xl="6" lg="12">
            <b-card class="form-group" style="margin-bottom: 0;">
              <h4 class="card-title" style="display: flex; align-items: center;">
                <svgicon class="icon mr-2" icon="item-windows" width="24" height="24" />
                <span style="margin-top: 4px;">Windows x86</span>
              </h4>
              <b-card-text>
                <fg-input v-model="windowsX86InstallationPath"
                  id="windowsx86_installation_path"
                  :label="t('installationPathLabel')"
                  name="windowsx86_installation_path"
                  placeholder="e.g. https://csp_server/endpointsecure_windows_x86/ep_win_86.exe"
                  autocapitalize="yes"
                  v-validate="'url|max:1024'"
                  :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/endpoint-secure/settings"}]'
                  :error="getError('windowsx86_installation_path')">
                </fg-input>
              </b-card-text>
              <b-card-text>
                <fg-input v-model="windowsX86UpdatePath"
                  id="windowsx86_update_path"
                  :label="t('updatePathLabel')"
                  name="windowsx86_update_path"
                  placeholder="e.g. https://csp_server/endpointsecure_windows_x86/ep_win_86_update.exe"
                  autocapitalize="yes"
                  v-validate="'url|max:1024'"
                  :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/endpoint-secure/settings"}]'
                  :error="getError('windowsx86_update_path')">
                </fg-input>
              </b-card-text>
              <b-card-text style="padding-left: 20px;">
                <label for='windowsx86_version'>{{t('versionNumberLabel')}}</label>
                <b-row style="margin-left: 0; margin-right: 0;">
                  <b-col style="padding-left: 0; padding-right: 0;" xl="3" lg="12">
                    <fg-input v-model="windowsX86Version"
                      id="windowsx86_version"
                      name="windowsx86_version"
                      placeholder="e.g. 10.1.123456"
                      autocapitalize="yes"
                      v-validate="'max:256'"
                      :visible_for_permissions='[{actions: ["PATCH"], path: "/subsystems/endpoint-secure/settings"}]'
                      :error="getError('windowsx86_version')">
                    </fg-input>
                  </b-col>
                  <b-col xl="9" lg="12" style="padding-right: 0;">
                    <p class="mt-2">{{t('versionNumberWarning')}}</p>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </form>
    </b-card>
  </b-container>
</template>

<script>

export default {
  name: 'download-details',
  data() {
    return {
      androidAppId: '',
      iosAppName: '',
      windowsX64InstallationPath: '',
      windowsX64UpdatePath: '',
      windowsX64Version: '',
      macOsInstallationPath: '',
      macOsUpdatePath: '',
      macOsVersion: '',
      windowsX86InstallationPath: '',
      windowsX86UpdatePath: '',
      windowsX86Version: '',

      loading: false,
    }
  },
  computed: {
    disableSubmit() {
      return this.errors.items.length || this.loading;
    },
  },
  methods: {
    async saveDetails() {
      this.loading = true;

      const settings = {
        android:  {
          application_id: this.androidAppId,
        },
        ios: {
          application_id: this.iosAppName,
        },
        windows: {
          arch_32: {
            installer_uri: this.windowsX86InstallationPath,
            updater_version: this.windowsX86Version,
            updater_uri: this.windowsX86UpdatePath,
          },
          arch_64: {
            installer_uri: this.windowsX64InstallationPath,
            updater_version: this.windowsX64Version,
            updater_uri: this.windowsX64UpdatePath,
          },
        },
        macos: {
          installer_uri: this.macOsInstallationPath,
          updater_version: this.macOsVersion,
          updater_uri: this.macOsUpdatePath,
        },
      };

      await this.$store.dispatch('endpointsecure/updateEndpointSecureSettings', settings);

      this.loading = false;
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async setSettings() {
      await this.$store.dispatch('endpointsecure/getEndpointSecureSettings');

      const settings = this.$store.getters['endpointsecure/endpointSecureSettings'];

      Object.assign(this, {
        ...(settings.android && {
          androidAppId: settings.android.application_id
        }),
        ...(settings.ios && {
          iosAppName: settings.ios.application_id
        }),
        ...(settings.windows && settings.windows.arch_64 && {
          windowsX64InstallationPath: settings.windows.arch_64.installer_uri
        }),
        ...(settings.windows && settings.windows.arch_64 && {
          windowsX64UpdatePath: settings.windows.arch_64.updater_uri
        }),
        ...(settings.windows && settings.windows.arch_64 && {
          windowsX64Version: settings.windows.arch_64.updater_version
        }),
        ...(settings.windows && settings.windows.arch_32 && {
          windowsX86InstallationPath: settings.windows.arch_32.installer_uri
        }),
        ...(settings.windows && settings.windows.arch_32 && {
          windowsX86UpdatePath: settings.windows.arch_32.updater_uri
        }),
        ...(settings.windows && settings.windows.arch_32 && {
          windowsX86Version: settings.windows.arch_32.updater_version
        }),
        ...(settings.macos && {
          macOsInstallationPath: settings.macos.installer_uri
        }),
        ...(settings.macos && {
          macOsUpdatePath: settings.macos.updater_uri
        }),
        ...(settings.macos && {
          macOsVersion: settings.macos.updater_version
        }),
      });
    },
  },
  mounted() {
    this.setSettings();
  },
}
</script>
